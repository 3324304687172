import React, { useEffect, useContext, useState } from "react";
import {
	ReportProblem,
	Category,
	PersonAdd,
	PhoneIphone,
} from "@mui/icons-material";
import { request } from "../../utils/axios";
import { AuthContext } from "../../contexts/AuthProvider";

const StatsBox = ({ data, icon, desc }) => {
	return (
		<div className="border border-bgNeutral-light dark:border-bgNeutral-dark rounded-lg w-full md:w-full p-5 flex flex-col flex-1 bg-bgNeutral-light dark:bg-bgNeutral-elementdark shadow-custom">
			<div className="flex items-center">
				<figure className="rounded-md w-10 h-10 flex items-center">
					<i className="text-textNeutral-dark dark:text-textNeutral-light">
						{icon}
					</i>
				</figure>
                <div>
					<h3 className="text-textNeutral-dark dark:text-textNeutral-light font-semibold text-xl">
						{data}
					</h3>
				</div>
			</div>
			<div className="mt-2 flex flex-col px-1">
				<p className="w-2/3 text-textNeutral-dark dark:text-textNeutral-light text-xs">
					{desc}
				</p>
			</div>
		</div>
	);
};

const BigStatsBox = ({ title, icon, data }) => {
	return (
		<div className="rounded-lg shadow-custom h-full flex flex-col p-5  bg-bgNeutral-light dark:bg-bgNeutral-elementdark">
			<div className="flex items-center">
				<figure className="rounded-md w-10 h-10">
					<i className="text-textNeutral-dark dark:text-textNeutral-light">
						{icon}
					</i>
				</figure>
			</div>
			<div className="mb-5">
				<h3 className="text-textNeutral-dark dark:text-textNeutral-light font-semibold text-xl">
					{title}
				</h3>
			</div>
			<div className="my-auto">
				<ul className="flex flex-col gap-y-3 px-6">
					{data.map((item, index) => {
						return (
							<li
								key={index}
								className="border-b border-bgNeutral-divide flex justify-between pb-1 last:border-0"
							>
								<div className="flex">
									<h4 className="text-xs font-light text-textNeutral-dark dark:text-textNeutral-light">
										{item.title}
									</h4>
									<span></span>
								</div>
								<span className="font-semibold text-xs text-textNeutral-dark dark:text-textNeutral-light">
									{item.value}
								</span>
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
};

const Stats = () => {
	const { user } = useContext(AuthContext);
	const [registeredUsers, setRegisteredUsers] = useState(0);
	const [newUsers, setNewUsers] = useState(0);
	const [popularCategories, setPopularCategories] = useState([]);
	const [recentReports, setRecentReports] = useState(0);

	useEffect(() => {
		request(
			"/reports?date=last_30_days",
			"get",
			null,
			user.accessToken
		).then((res) => {
			setRecentReports(res.count);
		});
		request(
			"/reports?group=category&aggregate=count",
			"get",
			null,
			user.accessToken
		).then((res) => {
			let transformedValues = [];
			res.data.forEach((item) => {
				transformedValues.push({
					title: item.Category.name,
					value: item.count,
				});
			});
			transformedValues.sort((a, b) => b.value - a.value);
			setPopularCategories(transformedValues);
		});

		if (user.role === "ROLE_ADMIN") {
			request("/users?role=citizen", "get", null, user.accessToken).then(
				(res) => {
					setRegisteredUsers(res.count);
				}
			);
			request(
				"/users?role=citizen&date=last_30_days",
				"get",
				null,
				user.accessToken
			).then((res) => {
				setNewUsers(res.count);
			});
		}
	}, [
		setRegisteredUsers,
		setNewUsers,
		setPopularCategories,
		setRecentReports,
	]);

	if (!user) return null;

	return (
		<div className="w-full flex flex-col xl:justify-between lg:flex-row gap-x-5 gap-y-5 xl:gap-y-0">
			<div className="flex flex-row lg:flex-col flex-1 justify-between gap-y-4 gap-x-4">
				{popularCategories.length > 1 && (
					<div className="flex-1">
						<BigStatsBox
							data={popularCategories}
							title="Catégories populaires"
							icon={<Category sx={{ fontSize: 28 }} />}
						/>
					</div>
				)}
			</div>
			<div className="flex flex-col justify-between gap-y-4">
				{/*<StatsBox*/}
				{/*    data="15"*/}
				{/*    icon={<PostAdd sx={{fontSize: 28}}/>}*/}
				{/*    desc="Nouveaux articles sur le blog communal."*/}
				{/*/>*/}
				<div className="flex flex-1">
					<StatsBox
						data={recentReports}
						icon={<ReportProblem sx={{ fontSize: 28 }} />}
						desc="Signalements déclarés au cours des 30 derniers jours."
					/>
				</div>
				{user.role === "ROLE_ADMIN" && (
					<div className="flex flex-col gap-y-4">
						<StatsBox
							data={newUsers}
							icon={<PersonAdd sx={{ fontSize: 28 }} />}
							desc="Nouvelles inscriptions."
						/>
						<StatsBox
							data={registeredUsers}
							icon={<PhoneIphone sx={{ fontSize: 28 }} />}
							desc="Citoyens enregistrés."
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default Stats;

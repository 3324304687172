// External librairies
import React, {useMemo} from "react";
import {Calendar, momentLocalizer} from 'react-big-calendar'
import "react-big-calendar/lib/css/react-big-calendar.css"
import moment from "moment";
import 'moment/locale/fr';

// Queries
import {useQueryAgenda} from "../../queries";

// Components
import {ActionHeader, Toolbar} from "../../components";

// Misc
import {Loader} from "../../utils";
import {Transition} from "../../utils/";
import {useNavigate} from "react-router-dom";

const AgendaPage = () => {
    const localizer = momentLocalizer(moment);
    const navigate = useNavigate()

    const {data: dataAgenda, isLoading: isLoadingAgenda} = useQueryAgenda();

    const {views, ...otherProps} = useMemo(() => ({
        views: {
            month: true,
            week: true
        },
    }), [])

    const eventPropGetter = (event) => {
        const newEvent = {...event}
        newEvent.className = `${event.color}`
        return newEvent;
    }

    const onSelectEvent = (event) => {
        navigate("/agenda/" + event.id + "/brief", {state: {id: event.id}})
    }

    const actionData = [];
    if (process.env.REACT_APP_AGENDA === "default") {
        actionData.push({
            id: 0,
            route: "create",
            label: "Ajouter un événement"
        })
    }

    return (
        <>
            <div>
                <ActionHeader
                    icon="agenda"
                    title="Agenda"
                    hasReturn={false}
                    actionData={actionData}
                />
            </div>
            {isLoadingAgenda ? (
                <Loader/>
            ) : (
                <div className="h-full">
                    <Transition>
                        <div className="flex flex-col h-screen">
                            <Calendar
                                localizer={localizer}
                                events={dataAgenda}
                                startAccessor="start"
                                endAccessor="end"
                                views={views}
                                components={{toolbar: Toolbar}}
                                eventPropGetter={eventPropGetter}
                                popup={true}
                                messages={{
                                    showMore: (total) => <span className="ml-2 text-primary"
                                                               role="presentation">{`+${total} autre${total > 1 ? "s" : ""}`}</span>
                                }}
                                onSelectEvent={onSelectEvent}
                            />
                        </div>
                    </Transition>
                </div>
            )}
        </>
    );
};

export default AgendaPage;
